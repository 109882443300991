.paginate-nav {
  display: flex;
  justify-content: flex-end;
  gap: rem(4);
  padding-top: rem(30);
  &.center {
    justify-content: center;
  }
  .btn-paginate {
    @extend %flex-center;
    width: rem(30);
    height: rem(30);
    background-color: transparent;
    color: $color-jungle-green;
    font-size: $font-sm;
    border: none;
    &.btn-active {
      color: $color-white;
      background-color: $color-jungle-green;
      border-radius: rem(6);
    }
    &.double {
      svg {
        &:last-child {
          margin-left: rem(-8);
        }
      }
    }
  }
}
