.roles-modal {
  .form-multiselect {
    display: inline-flex;
    width: 50%;
    height: rem(150);
  }
  select {
    height: 100%;
  }
}
