.page-title {
  font-size: $font-title;
  color: $color-davys-grey;
  margin-top: rem(5);
  margin-bottom: rem(20);
}
.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: rem(5);
  margin-bottom: rem(20);
  &.institutional {
    flex-direction: column;
    justify-content: center;
    img + h2 {
      margin-top: rem(20);
    }
  }
  .page-title {
    margin: 0;
  }
}
.txt-main {
  color: $color-dim-grey;
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}
.img-main {
  margin: auto;
  display: flex;
  justify-content: center;
  padding-top: 30vh;
}
.page-text-box {
  &.content-use-terms, &.content-privacy {
    width: 80vw;
    max-width: rem(800);
    text-align: justify;
    padding-bottom: rem(50);
    margin: 0 auto;
    h2 {
      margin-top: rem(20);
      margin-bottom: rem(10);
      font-size: $font-lg;
    }
    p {
      margin-bottom: rem(10);
      .sub-paragraph {
        padding-left: rem(30);
        display: block;
        margin: rem(10) 0;
      }
    }
    > ul {
      padding-left: rem(30);
      margin-top: rem(10);
      margin-bottom: rem(10);
      li {
        margin: rem(5) 0;
      }
    }
    .border-text {
      padding: rem(10);
      border: rem(2) solid $color-black;
      margin-bottom: rem(15);
    }
    table {
      width: 100%;
      border-left: 1px solid $color-black;
      border-top: 1px solid $color-black;
      margin-top: rem(10);
      &.table-tree-columns {
        th, td {
          width: 33%;
        }
      }
      th, td {
        padding: rem(5);
        border-right: 1px solid $color-black;
        border-bottom: 1px solid $color-black;
      }
    }
  }
}
.page-use-terms, .page-privacy {
  .back-button {
    border: none;
    background-color: transparent;
    top: 2.09%;
    left: 2.22%;
    font-weight: bold;
    color: $color-link;
    font-size: rem(24);
    line-height: rem(30);
  }
}

.page-not-found {
  width: 100%;
  height: 100vh;
  .page-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80%;
    justify-content: center;
    align-items: center;
    img {
      width: rem(250);
    }
    h1 {
      margin: rem(10) 0;
    }
    button {
      margin: rem(10) 0 rem(30);
    }
  }
}
