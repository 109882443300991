.form-group {
  display: flex;
  flex-direction: column;
  padding-bottom: rem(10);
  label {
    font-size: $font-sm;
    color: $color-davys-grey;
    font-weight: $bold;
    margin-bottom: rem(4);
  }
  fieldset {
    display: flex;
    gap: rem(16);
    border: none;
  }
}

.form-control {
  background-color: $color-white;
  height: rem(30);
  font-size: $font-sm;
  color: $color-davys-grey;
  padding: rem(5) rem(10);
  border: 1px solid $color-sonic-silver;
  border-radius: rem(6);
  &::placeholder {
    color: $color-sonic-silver;
  }
  &:disabled {
    color: $color-gainsboro;
    background-color: $color-cultured;
  }
  &.error {
    &, &:after{
      color: $color-red-pigment !important;
    }
  }
}

.input-hide {
  display: none;
}

.datetime-placeholder {
  @extend %flex-center;
  height: 100%;
  font-size: $font-sm;
  color: $color-davys-grey;
}
