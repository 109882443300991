.panel-top-bar {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $color-white;
  width: 100%;
  height: rem(80);
  box-shadow: 0 rem(-2) rem(6) 0 rgba($color-black, 0.3);
  z-index: 10;
  > .logo-box {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: rem(200);
    height: 100%;
    text-align: center;
    padding: rem(20);
    background-color: $color-white;
    .hamburg-icon {
      position: relative;
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: flex-end;
      width: rem(18);
      height: rem(12);
      border-top: rem(2) solid $color-graphite;
      font-size: 0;
      color: transparent;

      &::before,
      &::after {
        content: '';
        display: flex;
        width: rem(18);
        height: rem(2);
        background-color: $color-graphite;
      }
      &::before {
        margin-bottom: rem(2);
      }
    }
    img {
      width: rem(105);
    }
  }
  > .user-info {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: rem(20);
    .profile {
      text-align: right;
      padding: 0 rem(16);
      .name {
        font-size: $font-lg;
      }
      .email {
        font-size: $font-xxs;
      }
    }
    .image {
      width: rem(45);
      height: rem(45);
      border-radius: 100%;
      overflow: hidden;
    }
    .toggle-menu {
      border: 0;
    }
    .content-dropdown {
      position: absolute;
      top: rem(50);
      right: 0;
      width: rem(230);
      background-color: $color-white;
      padding: rem(10);
      box-shadow: rem(3) rem(8) rem(15) rgba($color-black, 0.2);
      border-radius: rem(6);
      > ul {
        li {
          width: 100%;
          color: $color-spanish-gray;
          button, a {
            display: flex;
            align-items: center;
            background: transparent;
            font-size: $font-sm;
            color: $color-spanish-gray;
            border: 0;
            text-decoration: none;
            margin: rem(10);
            width: fit-content;
          }
          .item-icon {
            font-size: rem(20);
            margin-right: rem(15);
          }
        }
      }
    }
  }
}
.top-bar {
  display: flex;
  justify-content: space-between;
  height: rem(80);
  background-color: $color-white;
  .logo-box {
    padding: rem(10);
    display: flex;
    margin-left: rem(1);
    z-index: 1;
    img {
      width: rem(115);
      padding-top: rem(10);
      margin-left: rem(2);
    }
  }
  .login {
    color: $color-silver-chalice;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 4rem;
    font-weight: 700;
  }
}
