.btn-google-login {
  @extend %flex-center;
  gap: rem(10);
  min-width: rem(200);
  height: rem(40);
  font-size: $font-md;
  border-radius: rem(5);
  border: 0;
  background-color: transparent;
  color: $color-silver-chalice;
  font-weight: 700;
}
