.page-tenant-config {
  .form-tenant-config {
    max-width: rem(600);
    padding-top: rem(20);
    .form-group {
      position: relative;
      .field-help {
        display: none;
        position: absolute;
        right: rem(10);
        top: rem(6);
        width: rem(300);
        background: $color-white;
        padding: rem(10);
        border: 1px solid $color-gainsboro;
        z-index: 100;
        h3 {
          margin-bottom: rem(5);
        }
      }
      &.group_name_pattern{
        input {
          padding-right: rem(125);
        }
        &:after{
          content: '@%domain%';
          position: absolute;
          right: 0;
          bottom: rem(10);
          height: rem(42);
          background: $color-platinum;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 rem(10);
          border-top-right-radius: rem(6);
          border-bottom-right-radius: rem(6);
          color: $color-dim-grey;
          border: 1px solid $color-gainsboro;
        }
      }
    }
    .form-control {
      height: rem(42);
      border: none;
      border-radius: rem(6);
      box-shadow: 0 rem(4) rem(15) rgba($color-black, 0.02);
      border: 1px solid $color-gainsboro;
      &:focus {
        box-shadow: 0 rem(4) rem(15) rgba($color-black, 0.08);
        &+ .field-help {
          display: block;
        }
      }
    }
  }
}

.form-group {
  position: relative;
  .field-help {
    display: none;
    position: absolute;
    right: rem(00);
    top: rem(6);
    width: rem(300);
    background: $color-white;
    padding: rem(10);
    border: 1px solid $color-gainsboro;
    z-index: 100;
    h3 {
      margin-bottom: rem(5);
    }
  }
  &.group_name_pattern{
    input {
      padding-right: rem(125);
    }
    &:after{
      content: '@%domain%';
      position: absolute;
      right: 0;
      bottom: rem(10);
      height: rem(42);
      background: $color-platinum;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 rem(10);
      border-top-right-radius: rem(6);
      border-bottom-right-radius: rem(6);
      color: $color-dim-grey;
      border: 1px solid $color-gainsboro;
    }
  }
}
.form-control {
  height: rem(42);
  border: none;
  border-radius: rem(6);
  box-shadow: 0 rem(4) rem(15) rgba($color-black, 0.02);
  border: 1px solid $color-gainsboro;
  &:focus {
    box-shadow: 0 rem(4) rem(15) rgba($color-black, 0.08);
    &+ .field-help {
      display: block;
    }
  }
}

.modal-ou{
  width: rem(1200);
}
