.btn {
  display: block;
  width: fit-content;
  min-width: rem(130);
  background-color: transparent;
  font-size: $font-sm;
  color: $color-white;
  padding: rem(10);
  border: none;
  border-radius: rem(6);
  &:hover,
  &:active {
    background-color: darken($color-white, 5%);
  }
}
.btn-primary {
  @extend .btn;
  background-color: $color-primary;
  &:hover,
  &:active,
  &:focus {
    background-color: darken($color-primary, 5%);
  }
  &:disabled {
    background-color: rgba($color-primary, .60);
  }
}
.btn-secondary {
  @extend .btn;
  background-color: $color-secondary;
  &:hover,
  &:active {
    background-color: darken($color-secondary, 5%);
  }
}
.btn-green {
  @extend .btn;
  background-color: $color-jungle-green;
  &:hover,
  &:active {
    background-color: darken($color-jungle-green, 5%);
  }
  &:disabled {
    background-color: $color-gainsboro;
  }
}
.btn-white-green {
  @extend .btn;
  background-color: $color-white;
  border: 1px solid $color-jungle-green;
  color: $color-jungle-green;
  &:hover,
  &:active {
    color: darken($color-jungle-green, 5%);
    border-color: darken($color-jungle-green, 5%);
  }
  &:disabled {
    color: $color-gainsboro;
    border-color: $color-gainsboro;
  }
}
.btn-icon {
  @extend .btn;
  background-color: transparent;
  min-width: auto;
  padding: rem(5);
  border-radius: rem(5);
  &:hover,
  &:active {
    &:not(:disabled) {
      background-color: darken($color-secondary, 5%);
    }
  }
  &:disabled {
    opacity: 0.4;
  }
}
