*,
*::before,
*::after {
  outline: 0;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

html,
body {
  background: $color-cultured;
  font-family: $font-family;
  font-size: 16px;
  font-weight: $light;
  color: $color-dim-grey;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-size: $font-sm;
  line-height: $line-sm;
}

ul {
  list-style-type: none;
}
 
strong {
  font-weight: $bold;
}

a {
  font-size: $font-sm;
  font-weight: $light;
  text-decoration: underline;
  cursor: pointer;
  color: $color-link;
}

img {
  max-width: 100%;
}

button {
  cursor: pointer
}

hr {
  border-color: $color-platinum;
  border-top: 0;
  border-right: 0;
  border-left: 0;
}

table {
  border-collapse: collapse;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
