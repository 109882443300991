.table-dashboard {
  width: 100%;
  border-radius: rem(6);
  background-color: $color-white;
  box-shadow: 0 rem(4) rem(15) rgba($color-black, 0.02);
  table {
    width: 100%;
    word-wrap: break-word;
    caption{
      padding: rem(14);
      font-weight: $semi-bold;
      font-size: $font-md;
      color: $color-davys-grey;
    }
    thead {
      th, td {
        text-align: left;
      }
      th {
        .order-button {
          cursor: pointer;
          .icon {
            margin-left: rem(5);
          }
        }
      }
    }
    tr:not(:last-child) {
      th, td {
        border-bottom: 1px solid $color-light-gray;
      }
    }
    th, td {
      height: rem(60);
      font-size: $font-xs;
      color: $color-davys-grey;
    }
    tbody {
      background-color: $color-platinum;
    }
  }
}

.table-list-user {
  width: 100%;
  border-radius: rem(6);
  background-color: $color-white;
  box-shadow: 0 rem(4) rem(15) rgba($color-black, 0.02);
  table {
    width: 100%;
    word-wrap: break-word;
    thead {
      th, td {
        text-align: left;
      }
    }
    tr:not(:last-child) {
      th, td {
        border-bottom: 1px solid $color-light-gray;
      }
    }
    th, td {
      height: rem(60);
      font-size: $font-xs;
      color: $color-davys-grey;
      &:first-child{
        padding-left: rem(15);
      }
      &:last-child {
        .content-users & {
          max-width: 15%;
          width: 100%;
        }
      }
    }
    tbody {
      background-color: $color-platinum;
      button {
        background: none;
        border: 0 none;
        margin: 0 rem(3);
        padding: rem(10);
        border-radius: 50%;
        &:hover{
          background-color: rgba(0, 0, 0, 0.08);
        }
      }
      select {
        background-color: transparent;
        border-radius: 6px;
        padding: 5px;
      }
      tr {
        &.row_editing {
          background: darken($color: $color-platinum, $amount: 5);
        }
      }
    }
  }
}

.table-list-processed-accounts {
  width: 100%;
  table {
    thead {
      background-color: $color-platinum;
      td {
        text-transform: capitalize;
        font-weight: $semi-bold;
      }
    }
    tbody {
      background-color: $color-white;
    }
    thead, tbody {
      tr {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: 1fr;
        gap: 0px;
        th,td {
          text-align: center;
          font-size: $font-sm;
          height: rem(40);
          @extend %flex-center;
        }
      }
    }
    td:not(:last-child) {
      border-right: 1px solid $color-light-gray;
    }
    span {
      width: 100%;
      display: inline-block;
    }
  }
}
