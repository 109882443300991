/* Crossbrowser mixin */

@mixin crossBrowser($property, $css) {
  -webkit-#{$property}: $css;
  -moz-#{$property}: $css;
  -o-#{$property}: $css;
  #{$property}: $css;
}

@mixin crossPlaceholder() {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin hideScrollBar() {
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0px;
  }
}

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }
  @-moz-keyframes #{$animationName} {
    @content;
  }
  @-o-keyframes #{$animationName} {
    @content;
  }
  @keyframes #{$animationName} {
    @content;
  }
}
