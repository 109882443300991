.text-bold {
  font-weight: $bold;
}

.text-center {
  text-align: center;
}

.text-upper {
  text-transform: uppercase;
}

@for $i from 1 through 10 {
  .pd-#{$i} {
    padding: #{rem(1 * $i)};
  }
  .mg-#{$i} {
    margin: #{rem(1 * $i)};
  }
  .pdt-#{$i} {
    padding-top: #{rem(1 * $i)};
  }
  .mgt-#{$i} {
    margin-top: #{rem(1 * $i)};
  }
  .pdr-#{$i} {
    padding-right: #{rem(1 * $i)};
  }
  .mgr-#{$i} {
    margin-right: #{rem(1 * $i)};
  }
  .pdb-#{$i} {
    padding-bottom: #{rem(1 * $i)};
  }
  .mgb-#{$i} {
    margin-bottom: #{rem(1 * $i)};
  }
  .pdl-#{$i} {
    padding-left: #{rem(1 * $i)};
  }
  .mgl-#{$i} {
    margin-left: #{rem(1 * $i)};
  }
}

.overlay-loading {
  @extend %flex-center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba($color-black, 0.3);
  color: $color-white;
  font-size: $font-md;
  font-weight: $bold;
  text-align: center;
  z-index: 1000;
  &::before {
    content: "";
    border: rem(12) solid $color-gainsboro;
    border-top: rem(12) solid $color-primary;
    border-radius: 50%;
    width: rem(120);
    height: rem(120);
    animation: spin 1.5s linear infinite;
  }
}

.boxTooltip {
  position: relative;
  &:after {
    display: none;
    position: absolute;
    top: 120%;
    right: 0%;
    border-radius: 3px;
    background-color: rgba(0,0,0,0.7);
    color: $color-white;
    content: attr(data-title);
    padding: rem(10);
    text-transform: none;
    font-size: $font-xs;
    white-space: nowrap;
    z-index: 99;
  }
  &:hover{
    &:after{
      display: block;
    }
  }
}

.helpBox {
  position: relative;
  width: auto !important;
  &.mounth {
    border: 1px solid #F7942C;
    width: rem(16) !important;
    height: rem(16);
    border-radius: 50%;
    right: rem(40);
    color: #F7942C;
    font-size: $font-xxs;
  }
  &:after {
    display: none;
    position: absolute;
    top: rem(27);
    right: rem(-20);
    border-radius: rem(6);
    background-color: #F7942C;
    color: $color-white;
    content: attr(data-title);
    padding: rem(10);
    text-transform: none;
    font-size: $font-xxs;
    white-space: nowrap;
    z-index: 99;
  }
  &:before {
    display: none;
    content: "";
    position: absolute;
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid #F7942C;
    top: rem(18);
    right: rem(-4);
    z-index: 99;
  }
  &:hover{
    &:after{
      display: block;
    }
    &:before{
      display: block;
    }
  }
}

.snackbar {
  position: fixed;
  right: 3%;
  bottom: 3%;
  border-radius: rem(6);
  display: flex;
  align-items: center;
  text-align: center;
  pointer-events: none;
  color: $color-white;
  padding: rem(15) rem(30);
  font-weight: $bold;

  &.show {
    visibility: visible;
    animation: fadeIn 0.5s, fadeOut 0.5s 3.5s;
  }

  &.hide {
    visibility: hidden;
  }

  &.success {
    background-color: $color-background-success;
  }

  &.warning {
    background-color: $color-background-warning;
  }

  &.error {
    background-color: $color-background-error;
  }

  &.info {
    background-color: $color-background-info;
  }
  
}
