.modal-import-users {
  .users-import {
    width: 100%;
    word-wrap: break-word;
    thead {
      tr {
        border-bottom: 1px solid $color-light-gray;
        padding-bottom: rem(8);
      }
      th, td {
        text-align: left;
      }
    }
    tr {
      display: grid;
      grid-template-columns: 1fr 1fr rem(50);
      th, td {
        text-align: left;
        display: flex;
        align-items: center;
      }
    }
    tr:not(:last-child) {
      th, td {
        border-bottom: 1px solid $color-light-gray;
      }
    }
    .btn-icon {
      color: $color-jungle-green;
    }
  }
}
