.form-search-menu {
  margin-top: rem(20);
  margin-bottom: rem(10);
  .search-box {
    position: relative;
    border-right: 0.88px solid;
    color: $color-light-gray;
    flex: 1;
    &.with-borders {
      border: rem(1) solid;
      border-radius: rem(6);
      margin-bottom: rem(5);
    }
    .search-icon {
      position: absolute;
      left: rem(8);
      top: rem(12);
    }
    .search-input {
      width: 100%;
      border: none;
      height: rem(42);
      padding-left: rem(45);
      border-radius: rem(6) 0 0 rem(6);
    }
  }
  .content-form {
    display: flex;
    align-items: center;
    width: 100%;
    box-shadow: 0 rem(4) rem(15) rgba($color-black, 0.02);
    border-radius: rem(6);
  }
  .select-box {
    position: relative;
    background-color: $color-platinum;
    height: rem(30);
    font-size: $font-sm;
    color: $color-davys-grey;
    padding: 0 rem(15);
    border: none;
    border-radius: rem(5);
    margin: 0 auto;
    > .react-datepicker-wrapper {
      position: absolute;
      top: 0;
      right: rem(5);
      pointer-events: none;
      opacity: 0;
      z-index: -1;
    }
  }
  .select-box-filters {
    display: flex;
    align-items: center;
    gap: rem(5);
    background-color: $color-white;
    width: fit-content;
    height: rem(42);
    color: $color-davys-grey;
    padding: 0 rem(5);
    border-radius: rem(5);
  }
}

.filter-dropdown {
  position: relative;
  &:after{
    content: '';
    display: inline-block;
    position: absolute;
    top: rem(12);
    right: rem(15);
    height: rem(5);
    width: rem(5);
    border-style: solid;
    border-width: rem(1) rem(1) 0 0;
    transform: rotate(135deg);
  }
  .filter-name {
    position: relative;
    background-color: $color-platinum;
    height: rem(30);
    font-size: $font-sm;
    color: $color-davys-grey;
    padding: 0 rem(30) 0 rem(15);
    border: 0 none;
    border-radius: rem(5);
    margin: 0 auto;
    &.open {
      background-color: darken($color: $color-platinum, $amount: 15);
    }
  }
  .content-dropdown {
    position: absolute;
    top: 120%;
    width: fit-content;
    background-color: $color-white;
    box-shadow: rem(3) rem(8) rem(15) rgba($color-black, 0.2);
    padding: rem(20) rem(20) rem(10);
    border-radius: rem(6);
    label {
      display: flex;
      align-items: center;
      white-space: nowrap;
      margin: rem(5) 0;
      input {
        margin-right: rem(5);
      }
    }
    button {
      background-color: $color-jungle-green;
      border-radius: rem(6);
      color: $color-white;
      font-size: $font-sm;
      border: 0 none;
      padding: rem(5) rem(10);
      display: block;
      margin: rem(8) auto 0;
    }
  }
}

