@keyframes panelSidebarOpen {
  0% {
    padding-left: 0;
  }
  100% {
    padding-left: rem(240);
  }
}

@keyframes panelSidebarClose {
  0% {
    padding-left: rem(240);
  }
  100% {
    padding-left: 0;
  }
}


.main-panel {
  display: flex;
  flex-flow: row nowrap;
  height: 100vh;
  padding-top: rem(80);
  .side-bar {
    position: fixed;
    background-color: $color-white;
    width: rem(240);
    height: 100vh;
    box-shadow: rem(5) rem(5) rem(6) 0px rgba($color-black, 0.14);
    z-index: 15;
    &:not(.loaded) {
      animation: 0.5s slideInLeft;
    }
    &.closing {
      animation: 0.5s slideOutLeft;
    }
    &.opening {
      animation: 0.5s slideInLeft;
    }
    &::before {
      content: '';
      position: absolute;
      top: -83px;
      right: 0;
      width: rem(250);
      height: rem(120);
      box-shadow: rem(-4) rem(-30) rem(10) rem(3) rgba($color-black, 0.3);
      z-index: 1;
      pointer-events: none;
    }
  }
  .listItem {
    padding: rem(10) rem(8);
    list-style-type: none;
    width: rem(240);
    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: rem(2);
      > a {
        text-decoration: none;
        display: flex;
        align-items: center;
        height: rem(50);
        width: 100%;
        padding: rem(10) 0 rem(10) rem(20);
        border-radius: rem(6);
        color: $color-graphite;
        background-color: $color-white;
        &:active,
        &:hover,
        &.item-active {
          background-color: $color-silver;
          transition: background-color 300ms linear;
        }
        svg {
          width: rem(20);
          height: rem(20);
        }
      }
    }
  }
  > .panel-content {
    width: 100%;
    animation: 0.5s panelSidebarClose;
    &.with-sidebar {
      width: 100%;
      padding-left: rem(240);
      animation: 0.5s panelSidebarOpen;
    }
  }
  .icon {
    margin-right: rem(12);
  }
  .users-totals {
    display: flex;
    align-items: center;
    gap: rem(120);
    padding: rem(20) rem(60) rem(30);
    .users-total {
      padding-left: rem(20);
      .number {
        font-size: $font-xl;
        color: $color-black;
        margin-left: rem(10);
      }
    }
  }
  .user-fila {
    padding-left: 25%;
  }
}
