.list-user-content {
  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: rem(30);
    padding-left: rem(16);
    padding-bottom: rem(10);
  }
  .select-row {
    @extend %flex-center;
    width: rem(30);
  }
}

.processed-accounts-box {
  margin-top: 30px;
}

