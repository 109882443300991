$color-black: #1b1b1b;
$color-white: #ffffff;

$color-graphite: #666666;
$color-dim-grey: #848484;
$color-spanish-gray: #999999;
$color-sonic-silver: #727272;
$color-davys-grey: #575757;
$color-silver: #f2f2f2f2;
$color-light-gray: #CCCCCC;
$color-cultured: #F8F8F8;
$color-gainsboro: #D9D9D9;
$color-platinum: #E4E4E4;
$color-mint-cream: #f3faf1;
$color-honeydew: #EFFBEB;
$color-silver-chalice: #909090;
$color-ash-gray: #cbd3c8;
$color-platinum: #EBEBEB;
$color-white-smoke: #f5f5f5;
$color-swan-gray: #b2b2b2;

$color-cyber-yellow: #FFD500;
$color-olive-drag: #869620;
$color-indian-drag: #358917;
$color-jungle-green: #38A77F;
$color-carnelian: #BD2020;
$color-red-pigment: #E91F1F;
$color-morning-blue: #829C9C;
$color-maximum-blue: #42a5c5 ;
$color-blue-munsell: #2296AA;
$color-denim: #3365AF;

$color-background-success: #43A047;
$color-background-warning: #FFA000;
$color-background-error: #c62828;
$color-background-info: #1976d2;

$color-primary: $color-jungle-green;
$color-secondary: $color-morning-blue;
$color-title: $color-dim-grey;
$color-subtitle: $color-spanish-gray;
$color-link: $color-davys-grey;
$color-icon: $color-davys-grey;
$color-error: $color-red-pigment;

// Font Family
$font-primary: 'Source Sans Pro';
$font-family: $font-primary, 'Helvetica', 'Arial', 'sans-serif';

// Font Weights
$light: 300;
$regular: 400;
$semi-bold: 600;
$bold: 700;

$font-xxs: rem(12);
$font-xs: rem(14);
$font-sm: rem(16);
$font-md: rem(18);
$font-lg: rem(22);
$font-title: rem(32);
$font-xl: rem(36);
$font-xxl: rem(40);

$line-xs: rem(16);
$line-sm: rem(20);
$line-md: rem(22);
$line-lg: rem(28);
$line-xl: rem(40);
$line-xxl: rem(46);

$margin-sm: rem(15);
$margin-md: rem(30);
$margin-lg: rem(45);
$margin-xl: rem(60);

// Breakpoints
$phone-sm: 576px;
$tablet-md: 768px;
$desktop-lg: 992px;
$desktop-xl: 1200px;
$desktop-xxl: 1600px;
