@keyframes shadowfadeIn {
  0% {
    background-color: rgba($color-black, 0);
  }
  100% {
    background-color: rgba($color-black, 0.25);
  }
}

@keyframes shadowfadeOut {
  0% {
    background-color: rgba($color-black, 0.25);
  }
  100% {
    background-color: rgba($color-black, 0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes stretchIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes stretchOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(320px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideOutRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(320px);
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-320px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideOutLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-320px);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
