.system-modal-default-overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba($color-black, 0.5);
  display: block;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
  @include hideScrollBar();
  z-index: 999;
}

.system-modal-default {
  position: relative;
  display: block;
  width: rem(550);
  background-color: $color-white;
  padding: rem(50);
  border-radius: rem(9);
  margin: rem(100) auto rem(50);
  overflow-x: hidden;
  z-index: 9999;
  &.alt-export-data {
    padding: rem(25) rem(30) rem(35);
  }
  &.sys-modal-large {
    width: rem(800);
  }
}

.modal-content {
  header {
    padding-bottom: rem(20);
    .alt-export-data &{
      padding-bottom: 0;
    }
    .modal-title {
      font-size: $font-lg;
      font-weight: $bold;
      color: $color-sonic-silver;
      text-align: center;
      text-transform: uppercase;
    }
  }
  .modal-sub-title {
    font-size: $font-md;
    font-weight: $bold;
    color: $color-sonic-silver;
    text-align: left;
    padding-bottom: rem(10);
  }
  .modal-section-content {
    font-size: $font-sm;
    font-weight: $regular;
    text-align: center;
    color: $color-dim-grey;
    padding-bottom: rem(20);
    p {
      padding-bottom: rem(20);
    }
    label {
      display: block;
      margin-bottom: rem(10);
    }
    .form-control {
      width: 100%;
      display: block;
      text-align: center;
    }
    input[type=text] {
      width: 100%;
      border-radius: rem(6);
      height: rem(40);
      font-size: $font-xs;
      .alt-export-data &{
        background-color: $color-white-smoke;
        border: 0 none;
        padding: rem(11) rem(13);
      }
      @include crossPlaceholder {
        color: $color-swan-gray;
        font-weight: $regular;
        font-size: $font-xs;
      }
    }
  }
  .modal-content-radio, .modal-content-checkbox {
    padding-bottom: rem(20);
    label {
      display: flex;
      align-items: center;
      font-size: $font-xxs;
      font-weight: $semi-bold;
      color: $color-sonic-silver;
      margin-bottom: rem(7);
      line-height: rem(16);
      input {
        margin-right: rem(5);
        height: rem(16);
        width: rem(16);
      }
    }
  }
  footer {
    display: flex;
    justify-content: center;
    gap: rem(20);
    > button {
      width: rem(220);
      text-transform: uppercase;
      font-weight: $bold;
    }
  }
}

.modal-delete-policy {
  .policy-name {
    color: $color-primary;
  }
}
