.page-logout {
  @extend %flex-center;
  width: 100%;
  height: 100vh;
  section {
    text-align: center;
    img {
      width: rem(180);
    }
    p {
      margin-top: rem(15);
      strong {
        display: block;
      }
    }
  }
}
